<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h1>Green Shade Project I</h1>
          </v-card-title>
          <v-card-text>
            <div class="project-intro">The first phase of the Green Shade project refers to providing financial services to commercial companies, such as financing guidance, investment guidance, venture capital guidance, due diligence, market research, etc. The following are the main goals and stages</div>
            <h3 class="my-6 mx-4">Currently 100% completed</h3>
            <v-progress-linear :value="100" color="primary"></v-progress-linear>
            <div class="project-feedback">static</div>
            <h3 class="my-6 mx-4">Evaluation Standards</h3>
            <template>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item,i) in phase1"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{item.title}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.intro }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <v-divider></v-divider>
            <h3 class="my-6 mx-4">Ad units</h3>
            <v-stepper alt-labels value="3">
              <v-stepper-header>
                <v-stepper-step step="1" complete>
                  Project approval

                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" complete>
                  Project funding and execution

                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" complete>
                  Project completion
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h1>Green Shade Project II</h1>
          </v-card-title>
          <v-card-text>
            <div class="project-intro">The Green Shade Phase II plan was born to adapt to the information age and the digital economy. Since the digital economy is high in technology, crimes and frauds are very secretive. In order to collect evidence at a deeper level, an Information Engineering College was established from Academy to train professional scholars and experts.</div>
            <h3 class="my-6 mx-4">Currently 100% completed</h3>
            <v-progress-linear :value="100" color="primary"></v-progress-linear>
            <div class="project-feedback">static</div>
            <h3 class="my-6 mx-4">Evaluation Standards</h3>
            <template>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item,i) in phase2"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{item.title}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.intro }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <v-divider></v-divider>
            <h3 class="my-6 mx-4">Ad units</h3>
            <v-stepper alt-labels value="3">
              <v-stepper-header>
                <v-stepper-step step="1" complete>
                  Established a college

                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" complete>
                  Teaching tasks advanced

                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" complete>
                  Students graduated
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h1>Green Shade Project III</h1>
          </v-card-title>
          <v-card-text>
            <div class="project-intro">The purpose of Green Shade Project III is to abstract the Green Shade entity, promote individuals and independent partners to jointly build an information sharing network, establish their own independent Green Shade branches and investigators as independent partners, and provide assistance through technical support and other means.</div>
            <h3 class="my-6 mx-4">Currently 34% completed</h3>
            <v-progress-linear :value="34" color="primary"></v-progress-linear>
            <div class="project-feedback">static</div>
            <h3 class="my-6 mx-4">Evaluation Standards</h3>
            <template>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item,i) in phase3"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{item.title}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.intro }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <v-divider></v-divider>
            <h3 class="my-6 mx-4">Ad units</h3>
            <v-stepper alt-labels value="2">
              <v-stepper-header>
                <v-stepper-step step="1" complete>
                  Recruit partners

                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">
                  Training and technical support
  
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  Build a network
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      phase1:[
        {
          title: 'Phase 1',
          intro: 'Phase 1 intro:Network Error',
        },
        {
          title: 'Phase 2',
          intro: 'Phase 2 intro:Network Error',
        }
      ],
      phase2:[
        {
          title: 'Phase 1',
          intro: 'Phase 1 intro:Network Error',
        },
        {
          title: 'Phase 2',
          intro: 'Phase 2 intro:Network Error',
        }
      ],
      phase3:[
        {
          title: 'Phase 1',
          intro: 'Phase 1 intro:Network Error',
        },
        {
          title: 'Phase 2',
          intro: 'Phase 2 intro:Network Error',
        }
      ],
    };
  }
};
</script>

<style scoped>
.project-intro {
  margin-bottom: 16px;
}

.project-feedback {
  margin-top: 16px;
}
</style>