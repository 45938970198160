

export default {
    message: {
      main:{
        "title":process.env.VUE_APP_PROGNAME,
        "subtitle":"General Solutions is the market-based service provided by Green Shadow",

      },
      redirect:{
        "header":"Redirecting to",

        "content":"You will be redirected to the target page in 3 seconds. If you do not want to wait, please click the button below.",
      },
      shareThisPage: 'Share this page',
      hello: 'Hello World!',
      welcome: 'Welcome to Vuetify!',
      application:  "Application",
      startup: 'Startup',
      drawback: 'Drawback',
      gs:{
        "policy": "Policy Research",
        "education":"Education Research",
        "financial": "Financial Research",
        "general": "General Research",
        "openapi": "Open API",
        "cooperate": "Cooperation",
        "oa": "Office Automation",
        "system": "Green Shade System",
        "docs":"Documents",
        "github": "Github",
        "contact": "Contact Us",
        "report": "Report",
        "fund": "Foundation",
        "website": "Website",
        "dept": "Department",
        "tech": "Technology",
        "inspect": "Disciplinary Committee",
      },
      routers:{
        "Doc":"Documents",
        "Home": "Home",
        "About": "About",
        "Notifications": "Notifications",
        "Profile": "Profile",
        "Projects": "Projects",
        "Aboutus" : "Aboutus",
        "Productions": "Productions",
        "Products": "Products",
        "ProductDetails": "Product Details",
        "Login": "Login",
        "Register": "Register",
      },
      footers:{
        "Product": "Product",
        "Platform": "Platform",
        "Support": "Support",
        "Company": "Company",
        "terms": "Terms",
        "privacy": "Privacy",
        "contact": "Contact Us",
        "security": "Security",
        "status":"Status",
        "docs":"Docs",
        "manageCookies":"Manage Cookies",
        "report":"Report",
        "icp":"ICP",
        "copyRight":"Copyright",
        "allRightsReserved":"All Rights Reserved",
        "poweredBy":"Powered by",
        "anti-fraud":"Anti-fraud",
        "filing":"Filing",
      },
      doc:{
        "TermsDoc": "Terms of Service",
        "PrivacyDoc": "Privacy Policy",
        "ContactDoc": "Contact Us",
        "SecurityDoc": "Security",
        "StatusDoc": "Status",
        "ManageCookiesDoc": "Manage Cookies",
        "IcpDoc": "ICP",
        "FilingDoc": "Filing",
        "AntiFraudDoc": "Anti-fraud",
        "ReportDoc": "Report",
        "DocsDoc": "Documents",
      },
      errorMsg:{
        "contentError":"Due to unknown reasons, the latest content cannot be loaded. The content you see below may have changed, but it is still valid within a certain range and has limited reference value. If you want to know why the problem occurred, please contact us by email or check the system status."
      }
    }
  };