<template>
  <div>
    <v-container>
      <!-- 图片居中盒子 -->
       <div style="" class="d-flex justify-center">
        <v-img src="../assets/greenshadecapital.jpg" height="100%"></v-img>
       </div>
      </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="display-2 mb-3">Professional Extensive Meticulous and Authentic</h1>
          
        </v-col>
      </v-row>
      
      <v-container >
          <v-row class="d-flex align-stretch h-100">
            <v-col cols="6" class="d-flex flex-column">
              <v-card class="flex-grow-1">
                <v-card-text class="d-flex justify-center align-center h-100">
                  <v-col>
                  <v-row>
                    <v-card-title>Customers</v-card-title>
                  </v-row>
                  <v-row>
                    <v-card-text>If you are a customers and looking for login or task operations, click the investigator page</v-card-text>
                  </v-row>
                </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                  color="deep-purple lighten-2"
                  @click="$router.push('/productions')"
                  text>
                    Introductions
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <v-card class="flex-grow-1">
                <v-card-text class="d-flex justify-center align-center h-100">
                  <v-col>
                  <v-row>
                    <v-card-title>Investigators</v-card-title>
                  </v-row>
                  <v-row>
                    <v-card-text>If you are a investigator and looking for login or task operations, click the investigator page</v-card-text>
                  </v-row>
                </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                  color="deep-purple lighten-2"
                  href="https://investigator.greenshadecapital.com/"
                  text>
                    investigator
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

    </v-container>

    <v-container>
      <h2 class="display-1 mb-3">Introduction</h2>
      <v-divider class="my-3 mx-2"></v-divider>
      <p style="font-size: large;">Green Shade Capital is a global venture capital consulting firm located in Los Angeles, USA. </p>
      <p style="font-size: large;"> We provide financial investigation services, such as due diligence, investment investigation, financial investigation, policy analysis, public relations, etc. Our main direction is anti-financial fraud and anti-financial crime. </p>
      <p style="font-size: large;">We have assisted governments and financial regulators in uncovering more than 3,000 financial violations in the past few years. This is because we have a large and extensive financial investigators and professional workers. Our investigators come from all walks of life, including but not limited to engineers, financial practitioners, corporate and industry leaders, financial institution executives, government leaders, students and scholars, writers and professional analysts. We have 1,145 formal professional employees, but there are more than 100,000 volunteers from all over the world. It is these volunteers and governments that are the main force in combating illegal crimes.</p>
      <p style="font-size: large;">NOTICE:We do not receive commercial assistance from any government or organization, nor do we accept any political stance. Sub-departments in various regions have 100% control over local services and management. This website, including the corporate entity headquartered in Los Angeles, is only used for the legal entity of the company and does not have any social nature.</p>
    </v-container>


    <v-container>
      <h2 class="display-1 mb-3">Services</h2>
      <v-divider class="my-3 mx-2"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <v-img src="../assets/invest.png" height="100%"></v-img>
            <v-card-title>Corporate Investigations</v-card-title>
            <v-card-text>
              Including due diligence, financial review, personnel investigation, industry consultation, etc.
           </v-card-text>
      
           <v-card-actions>
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="invest"
                >
                  More
                </v-btn>
              </v-card-actions>

       
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-img src="../assets/consult.png" height="100%"></v-img>
            <v-card-title>Consultation Service</v-card-title>
            <v-card-text>
              Financial and technical consulting, including business consulting, financial consulting, equity consulting, public relations consulting, etc.
              
            </v-card-text>
            <v-card-actions>
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="consult"
                >
                  More
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-img src="../assets/academy.png" height="100%"></v-img>
            <v-card-title>Training Services</v-card-title>
            <v-card-text>
              We have an independent academy that can provide professional personnel training, compliance training and management trainee training, etc.
            </v-card-text>
            <v-card-actions>
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="trainee"
                >
                  More
                </v-btn>
              </v-card-actions>

          </v-card>
        </v-col>


      </v-row>
    </v-container>
    <v-container>
      <h2 class="display-1 mb-3">Recruitment and Feedback</h2>
      <v-divider class="my-3 mx-2"></v-divider>
      <h3>Choose your identity or the identity you join the Green Shade</h3>
      <template>
              <v-expansion-panels>
                <!--Vounteer Pannel-->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    I'm a volunteer
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    If you join us as a volunteer, please use your usual email address to send a casual email to our recruitment email address. You will receive a PDF file containing a manual and guide, which details the codes, rules, regulations, remuneration and other information that you need to follow as a financial services volunteer.
                  </v-expansion-panel-content>
                </v-expansion-panel>
                    <!--Staff Pannel-->
                    <v-expansion-panel>
                  <v-expansion-panel-header>
                    I'm an official employee
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Official employees need to work in local branches. If you want to join us as a official employee, please first consult your country or region to find out whether the financial service institution or government allows you to join. If there is no Green Shade branch in the local area or if there is no cooperation with the local government or sovereign organization, you will not be able to work as a official employee.
                  </v-expansion-panel-content>
                </v-expansion-panel>
                    <!--Government and Office Pannel-->
                    <v-expansion-panel>
                  <v-expansion-panel-header>
                    I'm a government official or sovereign party member
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    GreenShade's non-entity nature prevents us from directly cooperating with government officials or financial entities, but if you are a government official or a manager of a financial entity, you can join us as a non-entity due to your special identity. GreenShade is not limited to a company or organization. Any individual or group willing to combat financial crimes and illegal financial activities can independently establish a branch of GreenShade and share financial intelligence around the world through open procedures and methods.
                  </v-expansion-panel-content>
                </v-expansion-panel>
                    <!--Individual Pannel-->
                    <v-expansion-panel>
                  <v-expansion-panel-header>
                    I am an independent collaborator (an organization or individual)
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    The Green Shade Phase III Project allows organizations or individuals to join Green Shade as independent collaborators, who are called individual independent collaborators. We aim to build a global financial information sharing platform called the Green Shade Network. The free sharing of financial information will lead to financial crimes losing their breeding ground. The top 10 economies in the world have reached independent cooperation agreements with us to share financial intelligence. You can contact the branch of the authorities or become a partner independently. Please contact the local department for details.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
      </template>
      <v-divider class="my-3 mx-2"></v-divider>
      <h3>Report a Financial Crime</h3>
      <p style="font-size: large;">If you want to report financial fraud or illegal activities, we provide a safe way, namely OSCP. You can download the OSCP client in the resources and fill in the information according to the instructions. OSCP can ensure that no trace is left on any device. If you have completed the report and evidence, please be sure to protect your personal safety. The investigator closest to you will contact you.</p>
      <v-list>
        <v-list-item>
          <v-text-field
            v-model="oscpemail"
            hint="Copy this email to your clipboard"
            label="OSCP Email"
            readonly 
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-text-field
            v-model="oscp"
            readonly 
            hint="Copy this endpoint to Open Security Connection Protocol client application"
            label="OSCP Endpoint"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
        <v-textarea
            v-model="oscppub"
            readonly 
            hint="Copy this public key to Open Security Connection Protocol client"
            label="OSCP PubKey"
          ></v-textarea>
        </v-list-item>
      </v-list>
      <p style="font-size: large;">Please be sure to check the feedback after submission, such as the completeness of the evidence and the legality of the materials.</p>
    </v-container>
    <v-container>
      <h2 class="display-1 mb-3">Subscribe</h2>
      <v-divider class="my-3 mx-2"></v-divider>
      <template>
      <v-form @submit.prevent="submitForm">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Your Email"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn type="submit" color="primary" block>
                Subscribe
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert type="info" text prominent border="left">
                Notice: By submitting this form, you agree to our  
                <a href="#" class="text-decoration-none">Terms of Service</a>. Your email address will be notified of any new changes.If you want to unsubscribe or subscribe to other content, please refer to the email content.
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    </v-container>

    <!-- 其他内容 -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      oscpemail:"oscpreport@greenshadecapital.com",
      oscp:"14:2E:B3:17:B7:58:56:CB:AE:50:09:40:E6:1F:AF:9D:8B:14:C2:C6:B0:F0:4B:3C:54:B7:8D:A3:A3:38:50:4C",
      oscppub:"ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQDCyyNmX96GOeOcn3qa0rxZNmN33oHH2DRZdJt3YE8tZ5+EGUCZ9YbHlLnurYWiM0wFQV6CtZioJ0CG6airRL/neGjfj81DqL4+caKHCwsFt82yLgp2NJ+UpiiGI8XZwOL5cY3L+GFfbwCTdrpCrPAuXcLnRDBGnt7lb4UQ17R0wzH8+bE3vHokti6y/+3Eg2xcNoLyvBlWQS3UjG/A+bP6pMcMp+Qrsxy/PSNmYyWpotNp+fiiKjrWQM0P1p0+Y899YUjVxrhyZQ5X4uhuXMWJEAJNMZyusATmel61m4j9raXqyQPfFGzU0T5FHijvm0Sj4rpbeqJGoXER8EfQriDMu0P/zoM3NHAlTUc8DVYHebFBDLVsgXW7lD+j5SY7Lpjh0Mv27Lv2X+B1TWhCm8hvLxnjjf8TtpG42CD7nunhw1Zt9HW5As03IzWCcVxoEnhxDLhNoh5tA6arlM6rErymIJ/zQ/qWRCUewz1FZZcBteReZX1ilwNKo/TV+jWftVr7VrkEy5YI6+I+6YPQjDlEieiQrY9hm48ujXHdGekjp92vMOMAZrBVqWWbm90SXF1fWRcy7Sjkn4ZlIUdZfKaQV88HV6VLm8SCe67iELnaHTkyZQ80CeF2S68wtF7ry7KxKzugNUzFixjtqcwQCThIY7rj5W5bVEfd0GI5I4X1BQ== 14:2E:B3:17:B7:58:56:CB:AE:50:09:40:E6:1F:AF:9D:8B:14:C2:C6:B0:F0:4B:3C:54:B7:8D:A3:A3:38:50:4C",
      slides: [
        {
          image: 'header1.png',
        },
        {
          image: 'header2.png',
        }
      ],
      defaultImage: require('@/assets/default.jpg'),
      email: '',
      emailRules: [
        v => !!v || 'e-mail is required',
        v => /.+@.+/.test(v) || 'e-mail must be valid',
      ],
    };
  },
  methods: {
    trainee(){this.$router.push('/productions#Training')},
    consult(){this.$router.push('/productions#Consultation')},
    invest(){this.$router.push('/productions#Investigations')},
    submitForm() {
      // 提交表单的逻辑
      console.log('提交的邮箱：', this.email);
    },
    getImageUrl(image) {
      try {
        return require(`@/assets/${image}`);
      } catch (error) {
        return this.defaultImage;
      }
    }
  }
};
</script>