export default {
    message: {
      main:{
        "title":process.env.VUE_APP_PROGNAME,
        "subtitle":"通用解决方案服务平台是绿荫提供的市场基础服务",
      },
      redirect:{
        "header":"您即将离开当前页面",
        "content":"请在跳转前确认",
      },
      shareThisPage: '分享此页面',
      hello: '你好，世界！',
      welcome: '欢迎使用 Vuetify!',
      application : '应用',
      startup: '启动器',
      drawback: '收起菜单',
      routers:{
        "Doc":"文档",
        "Home": "首页",
        "About": "关于",
        "Dashboard": "仪表盘",
        "Notifications": "通知",
        "Profile": "个人资料",
        "Settings": "设置",
        "Projects": "项目",
        "Aboutus" : "关于我们",
        "Productions": "产品",
        "Products": "产品",
        "ProductDetails": "产品细节",
        "Login": "登录",
        "Register": "注册",
      },
      gs:{
        "policy": "政策调研",
        "education":"教育调研",
        "financial": "金融调研",
        "general": "普通调研",
        "openapi": "API规范",
        "cooperate": "合作组织",
        "oa": "办公自动化",
        "system": "绿荫体系",
        "docs":"文档",
        "github": "Github",
        "contact": "联系我们",
        "report": "反馈",
        "fund": "基金会",
        "website": "官网",
        "dept": "组织部门",
        "tech": "技术支持",
        "inspect": "纪律审计委",
      },
        footers: {
          "Product": "产品",
          "Platform": "平台",
          "Support": "支持",
          "Company": "组织",
          "terms": "服务条款",
          "privacy": "隐私协议",
          "contact": "联系我们",
          "security": "安全",
          "status": "系统状态",
          "docs": "文档支持",
          "manageCookies": "管理 Cookie",
          "report": "报告",
          "icp": "ICP备案",
          "copyRight": "版权",
          "allRightsReserved": "版权所有",
          "poweredBy": "技术支持",
          "anti-fraud": "反欺诈",
          "filing": "备案"
        },
        doc: {
          "TermsDoc": "服务条款",
          "PrivacyDoc": "隐私政策",
          "ContactDoc": "联系我们",
          "SecurityDoc": "安全",
          "StatusDoc": "状态",
          "ManageCookiesDoc": "管理 Cookies",
          "IcpDoc": "ICP 备案",
          "FilingDoc": "存档",
          "AntiFraudDoc": "反欺诈协议",
          "ReportDoc": "举报",
          "DocsDoc": "文档",
        },
        errorMsg:{
          "contentError":"由于未知原因，无法加载最新内容，您下面看到的内容可能已经改变，但仍在一定范围内有效，具有有限的参考意义，若您想了解为何出现问题，请通过邮件联系我们或是查看系统状态。"
        }
      
    }
  };