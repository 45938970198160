
<template>
    <v-container>
        
    <v-card>
        <v-toolbar color="primary" dark>
            <v-toolbar-title>Green Shade Branches/Investigators</v-toolbar-title>
          </v-toolbar>
      <v-card-text>
        <router-view></router-view>
      </v-card-text>
    </v-card>
</v-container>
</template>
  
  <script>
  export default {

  };
  </script>