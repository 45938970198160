<template>
    <v-card>
      <v-card-title class="headline">Branch : {{username}}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-avatar size="120">
              <img :src="employee.avatar" alt="avatar">
            </v-avatar>
          </v-col>
          <v-col cols="8">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Name:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.name }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Position:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.position }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Dept:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.department }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Number:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.number }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Location:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.location }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Code:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.email }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Communication:</v-list-item-content>
                <v-list-item-content class="align-end">{{ employee.phone }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        employee: {
          avatar: '',
          name: '',
          number: '',
          location: '',
          position: '',
          department: '',
          email: '',
          phone: ''
        }
      };
    },
    created(){
        this.username = this.$route.params.username;
    }
};
  
  </script>