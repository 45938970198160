<template>
  <div>
      <v-container>
          <v-row>
            <v-col>
              <v-card class="pa-5">
                <v-card-title>
                  <v-row>
                    <v-col class="text-left">
                      <h1 class="display-1">Production</h1>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <p>Here are the services we are providing. Click to reach the corresponding section</p>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#Investigations',{ offset: 50 })"
                  >
                  Investigations
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#Consultation',{ offset: 50 })"
                  >
                  Consultation
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#Training',{ offset: 50 })"
                  >
                  Training
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#PublicPolicyInvestigations',{ offset: 50 })"
                  >
                  Public Policy Investigations
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#PublicFinancialInvestigations',{ offset: 50 })"
                  >
                  Public Financial Investigations
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#PublicEducationInvestigations',{ offset: 50 })"
                  >
                  Public Education Investigations
                  </v-chip>
                  <v-chip
                    class="mr-2"
                    @click="$vuetify.goTo('#PublicGeneralInvestigations',{ offset: 50 })"
                  >
                  Public General Investigations
                  </v-chip>

                  <v-divider class="my-6 mx-4"></v-divider>
                  
                  <v-row class="mb-4">
                    <div id="Investigations">
                      <v-col>
                        <h2 class="headline mb-2">Green Shade Investigation Services</h2>
                        <p>The Green Shade Foundation is the company's financial arm dedicated to overseas investment, training of professional investigators and conducting investigative activities.</p>
                      
                      </v-col>
                    </div>
                  </v-row>
                  <v-row class="mb-4">
                  <div id="Consultation">
                    <v-col>
                      <h2 class="headline mb-2">Green Shade Consultation Services</h2>
                      <p>The Green Shade Consulting Team provides a variety of consulting services. If you have any questions about consulting, you can contact us.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>
                  <v-row class="mb-4">
                    <div id="Training">
                    <v-col>
                      <h2 class="headline mb-2">Green Shade Training Services</h2>
                      <p>You can join the training process as an independent collaborator, and our training program can provide you with professional talents and teams.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>
                  <v-row class="mb-4">
                    <div id="PublicPolicyInvestigations">
                    <v-col>
                      <h2 class="headline mb-2">Public Policy Investigations</h2>
                      <p>The public policy research section is a professional team that studies the economic policies of various countries and related economic events. If you need to analyze economic policies, or conduct content analysis for your team in combination with economic policies, please contact us.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>
  
                  <v-row class="mb-4">
                    <div id="PublicEducationInvestigations">
                    <v-col>
                      <h2 class="headline mb-2">Public Education Investigations</h2>
                      <p>Educational Research is the latest consulting service for educational institutions. You can contact our branch for the service you need.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>

                  
                  <v-row class="mb-4">
                    <div id="PublicFinancialInvestigations">
                    <v-col>
                      <h2 class="headline mb-2">Public Financial Investigations</h2>
                      <p>Financial solutions are complete solutions for startups and venture capital firms. If you are a technology company, you can get complete financial solutions through our services, and we will support you in all aspects.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>
                  
                  <v-row class="mb-4">
                    <div id="PublicGeneralInvestigations">
                    <v-col>
                      <h2 class="headline mb-2">Public General Investigations</h2>
                      <p>Public relations research is our new business, which is still in the development and expansion stage. If you are interested in it, you can contact us for cooperation.</p>
                      <v-btn class="primary">learn more</v-btn>
                    </v-col>
                  </div>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
  </div>
  
  </template>
  <script>
    export default {
      mounted() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          this.$vuetify.goTo(this.$route.hash, { offset: 50 });
        }
        console.log(this.$route.hash)
      });
    }
    }
  </script>