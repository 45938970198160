<template>
    <div><v-container>
        <v-alert
        v-show="show"
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="warning"
    >
    The page you are looking for cannot be located. It may be that the system is being migrated and cannot be redirected, or it may have been permanently moved to a new location.
    </v-alert>
        </v-container>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                show:false,

            }
        },
        created(){
            let queryName = this.$route.query.name;
            let mapping = {
                "policy":"/productions#PublicPolicyInvestigations",
                "education":"/productions#PublicEducationInvestigations",
                "financial":"/productions#PublicFinancialInvestigations",
                "general":"/productions#PublicGeneralInvestigations",
            }
            let mapResult = mapping[queryName];
            if (mapResult){
                this.$router.push(mapping[queryName]);
                return;
            }
            let urlMapping = {
                "fund":" https://foundation.greenshadecapital.com",
                "website": "https://greenshadecapital.com",
                "dept":"https://investigator.greenshadecapital.com",
                "inspect":"https://inspect.greenshadecapital.com",
            }
            if(urlMapping[queryName]){
                window.location.href = urlMapping[queryName];
                return;
            }
            this.show = true;
        }
    }

</script>